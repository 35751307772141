
import {
  Component,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { FetchPolicy } from 'apollo-client';
import BaseCard from '@/components/commonComponents/BaseCard.vue';
import DataTableHandler from '@/components/DataTable/DataTable';
import { Query } from '@/graphql/types';
import Message from '@/components/mixins/Message.vue';
import DataTable from '@/components/DataTable/DataTable.vue';

@Component({
  name: 'ReportList',
  components: {
    BaseCard,
    DataTable,
  },
})
export default class ReportList extends Vue {
  private dataTable = new DataTableHandler('reportList');

  private title = this.$route.params.navigationSlug.charAt(0)
    .toUpperCase() + this.$route.params.navigationSlug.slice(1);

  private loading = false;

  private hasExport = false;

  @Watch('dataTable.options')
  optionsChanged(): void {
    this.fetchData('network-only');
  }

  mounted(): void {
    this.dataTable.hasTotals = true;
  }

  private import(): Promise<Query> {
    const navigationGroup = this.$route.params.navigationGroup as string;
    switch (navigationGroup) {
      case 'status':
        return import('@/graphql/queries/status-report');
      case 'label':
        return import('@/graphql/queries/tag-report');
      case 'assign':
      case 'toewijzen':
        return import('@/graphql/queries/assign-report');
      case 'group':
      case 'groep':
        return import('@/graphql/queries/group-report');
      default:
        throw Error(`No import found for the report page with the navigation group: ${navigationGroup}`);
    }
  }

  private fetchData(fetchPolicy: FetchPolicy = 'cache-first') {
    this.loading = true;

    const variables = {
      ...this.dataTable.getQueryVariables(),
      slug: this.$route.params.navigationSlug,
    };

    this.import()
      .then(({ QUERY: query }) => this.$apollo.query({
        fetchPolicy,
        query,
        variables,
      }))
      .then((response) => {
        this.dataTable.handleData(response.data);
        this.hasExport = response.data.list.dataGridInfo.metadata['list-settings']?.export ?? false;
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private importExportQuery(): Promise<Query> {
    const navigationGroup = this.$route.params.navigationGroup as string;
    switch (navigationGroup) {
      case 'status':
        return import('@/graphql/queries/export-status-report');
      case 'label':
        return import('@/graphql/queries/export-tag-report');
      case 'assign':
      case 'toewijzen':
        return import('@/graphql/queries/export-assign-report');
      case 'group':
      case 'groep':
        return import('@/graphql/queries/export-group-report');
      default:
        throw Error(`No import found for the report page with the navigation group: ${navigationGroup}`);
    }
  }

  private exportFile(): void {
    this.loading = true;

    const variables = {
      sorters: this.dataTable.getQueryVariables().sorters,
      slug: this.$route.params.navigationSlug,
    };

    this.importExportQuery()
      .then(({ QUERY: query }) => this.$apollo.query({
        query,
        variables,
      }))
      .then((response) => {
        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data.export.data}`;
        const downloadLink = document.createElement('a');
        const fileName = response.data.export.name;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
